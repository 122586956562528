body {
  margin: 0;
  font-family:
    "Futura Std Light",
    "Shoogie Regular",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--color-body-background);
  color: #323030;
  overflow: hidden;
}

code {
  font-family: "Futura Std Light", "Shoogie Regular", source-code-pro, Menlo,
    Monaco, Consolas, "Courier New", monospace;
}

@font-face {
  font-family: "Shoogie Regular";
  font-style: normal;
  src:
    local("Shoogie Regular"),
    url("../public/fonts/SHOOGIE.woff") format("woff");
}

@font-face {
  font-family: "Futura Std Light";
  font-style: normal;
  font-weight: normal;
  src:
    local("Futura Std Light"),
    url("../public/fonts/FuturaStd-Light.woff") format("woff");
}

:root {
  --unit: 8px;

  /*fonts*/
  --font-size-massive: 56px;
  --font-size-huge: 48px;
  --font-size-large: 40px;
  --font-size-medium: 32px;
  --font-size-small: 24px;
  --font-size-tiny: 16px;

  /*background*/
  --color-body-background: #fbf1f3;
  --color-date-available: #c7ecee;

  /*font color*/
  --color-text-primary: #323030;
}
